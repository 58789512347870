var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.transaction
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "kt-subheader kt-grid__item",
            attrs: { id: "kt_subheader" },
          },
          [
            _c("div", { staticClass: "kt-container kt-container--fluid" }, [
              _c("div", { staticClass: "kt-subheader__main" }, [
                _vm.deviceType !== "mobile" && _vm.deviceType !== "tablet"
                  ? _c("h3", { staticClass: "kt-subheader__title" }, [
                      _vm._v(" Check / ETF Agreements "),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "kt-subheader__toolbar" }, [
                _c(
                  "div",
                  { staticClass: "kt-subheader__wrapper" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.add-agreement",
                            modifiers: { "add-agreement": true },
                          },
                        ],
                        staticClass: "btn btn-label-success btn-bold",
                      },
                      [
                        _c("i", { staticClass: "flaticon2-user" }),
                        _vm._v(" Add Agreement "),
                      ]
                    ),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: "add-agreement",
                          centered: "",
                          "no-close-on-backdrop": "",
                        },
                        on: { ok: _vm.addAgreement },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modal-header",
                              fn: function () {
                                return [_c("h5", [_vm._v("Add Agreement")])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3188595209
                        ),
                      },
                      [
                        _c("div", { staticClass: "form-group validated" }, [
                          _c("label", [_vm._v("Agreement Id")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.extAgreementId,
                                expression: "extAgreementId",
                              },
                            ],
                            staticClass: "form-control",
                            class: !_vm.agreementError ? "" : "is-invalid",
                            attrs: {
                              type: "text",
                              placeholder: "POC 040 20233030116",
                            },
                            domProps: { value: _vm.extAgreementId },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.extAgreementId = $event.target.value
                              },
                            },
                          }),
                          _vm.extAgreementId && _vm.agreementError
                            ? _c(
                                "div",
                                { staticClass: "form-text invalid-feedback" },
                                [_vm._v(" " + _vm._s(_vm.agreementError) + " ")]
                              )
                            : _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    " The Agreement IDs can be found by performing a `Checks/EFTs Summary Search`` in PIP for EFT / Check Number: " +
                                      _vm._s(
                                        _vm.$route.params.transactionCheckNumber
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                        ]),
                        !_vm.agreementError
                          ? [
                              _c(
                                "div",
                                { staticClass: "form-group validated" },
                                [
                                  _c("label", [_vm._v("Agreement Amount")]),
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.agreementAmount,
                                          expression: "agreementAmount",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.agreementAmount
                                        ? ""
                                        : "is-invalid",
                                      attrs: { type: "number" },
                                      domProps: { value: _vm.agreementAmount },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.agreementAmount =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "input-group-append" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formattedAgreementAmount
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  !_vm.agreementAmount
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-text invalid-feedback",
                                        },
                                        [_vm._v(" Please enter an amount. ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
          },
          [
            _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "kt-widget12" }, [
                  _c("div", { staticClass: "kt-widget12__content" }, [
                    _c("div", { staticClass: "kt-widget12__item" }, [
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c("span", { staticClass: "kt-widget12__desc" }, [
                          _vm._v("Check / EFT Number"),
                        ]),
                        _c(
                          "span",
                          { staticClass: "kt-widget12__value kt-font-primary" },
                          [
                            _vm._v(
                              _vm._s(_vm.transaction.transactionCheckNumber)
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c("span", { staticClass: "kt-widget12__desc" }, [
                          _vm._v("Check Total"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "kt-widget12__value",
                            class: [
                              _vm.transaction.transactionAmount ==
                              _vm.agreementTotal
                                ? "kt-font-success"
                                : "kt-font-danger",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(
                                  _vm.transaction.transactionAmount
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c("span", { staticClass: "kt-widget12__desc" }, [
                          _vm._v("Agreement Total"),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "kt-widget12__value",
                            class: [
                              _vm.transaction.transactionAmount ==
                              _vm.agreementTotal
                                ? "kt-font-success"
                                : "kt-font-danger",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(_vm.agreementTotal))
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-striped table-hover" },
                    [
                      _vm._m(0),
                      _c(
                        "tbody",
                        _vm._l(_vm.transaction.agreements, function (item) {
                          return _c(
                            "tr",
                            {
                              key: `agreement_${item.schoolSubscriptionAgreementId}`,
                            },
                            [
                              _c("td", [
                                _vm._v(" " + _vm._s(item.extAgreementId) + " "),
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(item.agreementAmount)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [_vm._v(" N/A ")]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Agreement Id ")]),
        _c("th", [_vm._v(" Agreement Amount ")]),
        _c("th", [_vm._v(" Status ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }