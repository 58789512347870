<template>
<div v-if="transaction">
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3
                    v-if="deviceType !== 'mobile' && deviceType !== 'tablet'"
                    class="kt-subheader__title"
                >
                    Check / ETF Agreements
                </h3>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper">
                    <b-button
                        v-b-modal.add-agreement
                        class="btn btn-label-success btn-bold"
                    >
                        <i class="flaticon2-user" />
                        Add Agreement
                    </b-button>

                    <b-modal
                        id="add-agreement"
                        centered
                        no-close-on-backdrop
                        @ok="addAgreement"
                    >
                        <template #modal-header>
                            <h5>Add Agreement</h5>
                        </template>

                        <div class="form-group validated">
                            <label>Agreement Id</label>
                            <input
                                v-model="extAgreementId"
                                type="text"
                                class="form-control"
                                placeholder="POC 040 20233030116"
                                :class="!agreementError ? '' : 'is-invalid'"
                            >

                            <div
                                v-if="extAgreementId && agreementError"
                                class="form-text invalid-feedback"
                            >
                                {{ agreementError }}
                            </div>
                            <span
                                v-else
                                class="form-text text-muted"
                            >
                                The Agreement IDs can be found by performing a
                                `Checks/EFTs Summary Search`` in PIP
                                for EFT / Check Number: {{ $route.params.transactionCheckNumber }}
                            </span>
                        </div>
                        <template v-if="!agreementError">
                            <div class="form-group validated">
                                <label>Agreement Amount</label>
                                <div class="input-group">
                                    <input
                                        v-model="agreementAmount"
                                        type="number"
                                        :class="agreementAmount ? '' : 'is-invalid'"
                                        class="form-control"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ formattedAgreementAmount }}</span>
                                    </div>
                                </div>
                                <div
                                    v-if="!agreementAmount"
                                    class="form-text invalid-feedback"
                                >
                                    Please enter an amount.
                                </div>
                            </div>
                        </template>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-widget12">
                    <div class="kt-widget12__content">
                        <div class="kt-widget12__item">
                            <div class="kt-widget12__info">
                                <span class="kt-widget12__desc">Check / EFT Number</span>
                                <span class="kt-widget12__value kt-font-primary">{{ transaction.transactionCheckNumber }}</span>
                            </div>
                            <div class="kt-widget12__info">
                                <span class="kt-widget12__desc">Check Total</span>
                                <span
                                    class="kt-widget12__value"
                                    :class="[
                                        transaction.transactionAmount == agreementTotal ? 'kt-font-success' : 'kt-font-danger'
                                    ]"
                                >{{ formatCurrency(transaction.transactionAmount) }}</span>
                            </div>
                            <div class="kt-widget12__info">
                                <span class="kt-widget12__desc">Agreement Total</span>
                                <span
                                    class="kt-widget12__value"
                                    :class="[
                                        transaction.transactionAmount == agreementTotal ? 'kt-font-success' : 'kt-font-danger'
                                    ]"
                                >{{ formatCurrency(agreementTotal) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Agreement Id
                                </th>
                                <th>
                                    Agreement Amount
                                </th>
                                <th>
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in transaction.agreements"
                                :key="`agreement_${item.schoolSubscriptionAgreementId}`"
                            >
                                <td>
                                    {{ item.extAgreementId }}
                                </td>
                                <td>
                                    {{ formatCurrency(item.agreementAmount) }}
                                </td>
                                <td>
                                    N/A
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import numeral from 'numeral';
import Types from '../../../store/Types';
import * as network from '../../../network';

export default {
    name: 'TransactionDetails',
    data() {
        return {
            extAgreementId: null,
            agreementAmount: 0,
        };
    },
    computed: {
        ...mapState({
            transactions: (state) => state.database.transactions,
            schools: (state) => state.database.schools,
            deviceType: (state) => state.deviceType,
        }),
        agreementTotal() {
            if (!this.transaction) return 0;
            return this.transaction.agreements.map((a) => a.agreementAmount).reduce((a, b) => a + b);
        },
        agreementError() {
            if (this.agreementFormatError) return this.agreementFormatError;
            return null;
        },
        agreementFormatError() {
            const extAgreementId = (this.extAgreementId || '').trim();
            if (extAgreementId.substr(4) == ' ' && extAgreementId.substr(8) == ' ') {
                return 'Missing "POC " prefix eg: POC 040 20233030116';
            }
            return null;
        },
        formattedAgreementAmount() {
            return numeral(this.agreementAmount).format('$ 0,0[.]00');
        },
        transaction() {
            const { transactionCheckNumber } = this.$route.params;
            return this.transactions.find((t) => t.transactionCheckNumber == transactionCheckNumber) || null;
        },
        agreement() {
            const extAgreementId = (this.extAgreementId || '').trim();
            const {
                agreementAmount,
                transaction,
            } = this;

            if (!transaction || !extAgreementId || !agreementAmount) { return null; }

            return {
                schoolSubscriptionTransactionId: transaction.schoolSubscriptionTransactionId,
                extAgreementId,
                agreementAmount,
                deleted: false,
            };
        },
    },
    mounted() {
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }
        if (this.transactions.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_TRANSACTIONS);
        }

        this.agreementAmount = this.transaction?.transactionAmount || 0;
    },
    methods: {
        addAgreement() {
            const v = this;

            if (!this.agreement) return;
            const params = {
                url: {
                    schoolSubscriptionTransactionId: this.agreement.schoolSubscriptionTransactionId,
                },
                body: { agreement: this.agreement },
            };

            if (this.agreementError) {
                return v.showError(v.agreementError);
            }

            const { agreementAmount } = this;
            if (!agreementAmount) {
                return v.showError('Missing Amount');
            }

            network.admin.upsertAgreement(params, (err) => {
                if (err) v.showError(err);
                this.$store.dispatch(Types.actions.REFRESH_ADMIN_TRANSACTIONS);
            });
        },
    },
};
</script>

<style scoped>
tbody tr td {

    vertical-align: middle;
}
</style>
